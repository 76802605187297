import './App.css';
import { WrapperAnalytics } from './component/containers/WrapperAnalytics';
import { useAnalytics } from './hooks/useAnalytics';
import { MainRouter } from './routes/MainRouter';

function App() {
  const { initialized } = useAnalytics();

  return (
    <WrapperAnalytics initialized={initialized}>
        <MainRouter />
    </WrapperAnalytics>
  );
}

export default App;
