import { lazy, Suspense } from "react";
import { Route, Routes} from "react-router-dom";
import Loading from "../component/atoms/Loading";

const LayoutTaC = lazy(() => import("../pages/termandconditions/LayoutTermandCond").then(module => ({ default: module.LayoutTaC })));
const PrivatePolicyLayout = lazy(() => import("../pages/policy/PrivatePolicyLayout").then(module => ({ default: module.PrivatePolicyLayout })) )
const NotFoundPage = lazy(() => import("../pages/security/NotFoundPage").then(module => ({ default: module.NotFoundPage })) )
const Home = lazy(() => import("../pages/home/Home").then(module => ({ default: module.Home })));


export const MainRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={
                    <Suspense fallback={<Loading /> } >
                        <Home />
                    </Suspense>
                } />
                
                <Route path="/conditions" element={
                    <Suspense fallback={<Loading /> } >
                        <LayoutTaC />
                    </Suspense>
                } />

                <Route path="/policy" element={
                    <Suspense fallback={<Loading /> } >
                        <PrivatePolicyLayout />
                    </Suspense>
                } />

                <Route path="/*" element={
                    <Suspense fallback={<Loading /> } >
                        <NotFoundPage />
                    </Suspense>
                } />
            </Routes>
        </>
    );
};
